import React from "react";
import Header from "../Header/Header";
import Services from "../Services/Services";
import Work from "../Work/Work";
import Contact from "../Contact/Contact";

const Homepage = () => {
  return (
    <div>
      <Header />
      <Services />
      <Work />
      <Contact />
    </div>
  );
};

export default Homepage;
