import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { createClient } from "contentful";
import ReactMarkdown from "react-markdown";
import FormDetail from "../Contact/FormDetail";

const BlogDetails = () => {
  const [singleBlogPost, setSingleBlogPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = createClient({
    space: "8c55yd9zyc24",
    accessToken: "Tfaug5qMx0cPsywzbbSkgsOuy937Sx-ucAFkB7AjI0o",
  });

  const { slug } = useParams();
  console.log("SLUG:", slug);

  useEffect(() => {
    const getEntryBySlug = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "blog",
          "fields.slug": slug,
        });
        if (entries.items.length > 0) {
          setSingleBlogPost(entries.items[0]);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getEntryBySlug();
  }, [slug]);

  return (
    <div id="layout" className="">
      <div className="content">
        <div>
          <div className="posts">
            <Link to={"/blogList"} className="content-subhead">
              חזרה לבלוגים
            </Link>
            {loading ? (
              <div className="loading-spinner">
                <div className="spinner"></div>
              </div>
            ) : (
              <section className="post">
                <header className="post-header">
                  <img
                    src={singleBlogPost?.fields?.blogImage?.fields?.file?.url}
                    alt={singleBlogPost?.fields?.blogImage?.fields?.title}
                    width="578"
                    height="291"
                  />
                  <h1 className="post-title pt-3">
                    {singleBlogPost?.fields?.blogTitle}
                  </h1>
                  <p className="post-meta">
                    נכתב על ידי{" "}
                    <a href="" className="post-author">
                      {singleBlogPost?.fields?.blogAuthor} |
                    </a>{" "}
                    תאריך <span></span>
                    <small>{singleBlogPost?.fields?.createdDate}</small>
                  </p>
                </header>
                <div className="post-description-single">
                  <ReactMarkdown>
                    {singleBlogPost?.fields?.postContent}
                  </ReactMarkdown>
                </div>
              </section>
            )}
          </div>

          <div className="blog-footer">
            <Link to={"/blogList"}>חזרה</Link>
          </div>

          {/* הוספת טופס הפורם כאן */}
          <div className="contact-form-section">
            <h3>מעוניינים בפרטים נוספים? דברו איתנו...</h3>
            <FormDetail
              serviceID="service_d4ujcph"
              templateID="template_p9w3ynu"
              publicKey="pv_L3yy-dbxqxoN7r"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
