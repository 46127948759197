import "./App.css";
import "./assets/js/script";
import { Route, Routes } from "react-router-dom";
import BlogList from "./components/Blog/BlogList";
import Aos from "aos";
import { useEffect } from "react";
import Homepage from "./components/Homepage/Homepage";
import BlogDetails from "./components/Blog/BlogDetails";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Whatsapp from "./components/Whatsapp/Whatsapp";

function App() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blogList" element={<BlogList />} />
        <Route path="/blogDetails/:slug" element={<BlogDetails />} />
      </Routes>
      <Footer />
      <Whatsapp />
    </div>
  );
}

export default App;
