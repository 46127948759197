import React, { useEffect } from "react";
import "./Services.css";
import { services } from "../../constants/data";
import Aos from "aos";
import "aos/dist/aos.css";
 
const Services = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="services section-p" id="services">
      <div className="container" data-aos="fade-down">
        <div className="services-content">
          <div className="section-title">
            <h3 className="text-dark">השירותים שלנו</h3>
            <p className="text">מה אנחנו מציעים לכם.</p>
          </div>

          <div className="services-list grid">
            {services.map((service, index) => {
              return (
                <div className="services-item text-center" key={index}>
                  <div className="services-item-img">
                    <img src={service.image} className="mx-auto" alt="" />
                  </div>
                  <div className="services-item-text">
                    <h2 className="fw-5 op-07">{service.title}</h2>
                    <p className="fs-18 text mx-auto">{service.paragraph}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
