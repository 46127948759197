import images from "./images";

const services = [
  {
    image: `${images.service_1}`,
    title: "בניה ועיצוב 🚀",
    paragraph:
      "בנייה ועיצוב אישי של אתרי אינטרנט באמצעות קוד, המותאמים לכל סוגי העסקים המעוניינים למנף את העסק שלהם באינטרנט ולהגיע ליותר ויותר  לקוחות",
  },
  {
    image: `${images.service_2}`,
    title: "טכנולוגיה 🌐",
    paragraph:
      "אנו בונים אתרים בקוד באמצעות הטכנולוגיות ושפות התכנות המתקדמות ביותר, המאפשרות לבנות אתרים מהירים, מותאמים לנייד וידידותיים לשימוש",
  },
  {
    image: `${images.service_3}`,
    title: "קידום בגוגל 📊",
    paragraph:
      "לאחר הקמת האתר, תוכלו לקבל אצלנו שירותי קידום ולקדם את האתר באמצעות קידום אורגני בגוגל, וכך לקבל חשיפה גדולה יותר במנועי חיפוש",
  },
  // {
  //   image: `${images.service_3}`,
  //   title: "מובייל",
  //   paragraph:
  //     "אנו מתאימים את האתרים שאנחנו בונים לשימוש וגלישה נוחה בכל סוגי המכשירים הניידים, שכוללים פונקציונאליות של כפתור וואטצאפ שמוביל את הגולשים ישירות אליכם.",
  // },
  // {
  //   image: `${images.service_3}`,
  //   title: "שינוי תוכן באתר",
  //   paragraph:
  //     "אנו בונים אתרים בקוד באמצעות הטכנולוגיות ושפות התכנות המתקדמות ביותר, המאפשרות לבנות אתרים מהירים, נוחים וידידותיים לשימוש.",
  // },
  // {
  //   image: `${images.service_3}`,
  //   title: "מחירים נוחים",
  //   paragraph:
  //     "לאחר הקמת האתר, תוכלו לקבל אצלנו שירותי קידום ולקדם את האתר באמצעות קידום אורגני בגוגל, וכך לקבל חשיפה גדולה יותר במנועי חיפוש.",
  // },
];

const works = [
  {
    image: `${images.work_1}`,
    name: "gymfitness",
    link: "https://www.gymfitness.online/",
  },
  {
    image: `${images.work_2}`,
    name: "Tatto shot",
    link: "https://inked.onrender.com/",
  },
  {
    image: `${images.work_3}`,
    name: "Photographer",
    link: "https://photograph-ijdi.onrender.com/",
  },
  {
    image: `${images.work_4}`,
    name: "Camera Pro",
    link: "https://www.camera-pro.online/",
  },
];

const about_stats = [
  {
    image: `${images.stat_img_1}`,
    value: "1575",
    title: "Clients",
  },
  {
    image: `${images.stat_img_2}`,
    value: "2416",
    title: "Projects",
  },
  {
    image: `${images.stat_img_3}`,
    value: "4287",
    title: "Working Hours",
  },
  {
    image: `${images.stat_img_4}`,
    value: "287",
    title: "Awards",
  },
];

const testimonials = [
  {
    name: "Lelia Merritt",
    post: "Graphic Designer",
    paragraph:
      "Aliquam et odio arcu. Vestibulum pharetra tincidunt odio, sed pulvinar magna tempus quis. Mauris risus odio, semper sit amet tortor a, tristique consectetur urna.",
    image: `${images.head_shot_1}`,
  },

  {
    name: "John Huston",
    post: "Web Developer",
    paragraph:
      "Aliquam et odio arcu. Vestibulum pharetra tincidunt odio, sed pulvinar magna tempus quis. Mauris risus odio, semper sit amet tortor a, tristique consectetur urna.",
    image: `${images.head_shot_2}`,
  },
  {
    name: "Jeniffer Green",
    post: "Marketing Manager",
    paragraph:
      "Aliquam et odio arcu. Vestibulum pharetra tincidunt odio, sed pulvinar magna tempus quis. Mauris risus odio, semper sit amet tortor a, tristique consectetur urna.",
    image: `${images.head_shot_3}`,
  },
];

const logos = [
  {
    image: `${images.logo_img_1}`,
  },
  {
    image: `${images.logo_img_2}`,
  },
  {
    image: `${images.logo_img_3}`,
  },
  {
    image: `${images.logo_img_4}`,
  },
  {
    image: `${images.test_img_5}`,
  },
  {
    image: `${images.test_img_6}`,
  },
];

export { services, works, about_stats, testimonials, logos };
