  import React, { useEffect, useState } from "react";
  import { createClient } from "contentful";
  import { Link } from "react-router-dom";
  import "./Blog.css";

  const BlogList = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true); // מצב טעינה
    const client = createClient({
      space: "8c55yd9zyc24",
      accessToken: "Tfaug5qMx0cPsywzbbSkgsOuy937Sx-ucAFkB7AjI0o",
    });

    const getAllEntries = async () => {
      try {
        const entries = await client.getEntries();
        setBlogPosts(entries.items);
        setLoading(false); // סיים את מצב הטעינה
      } catch (error) {
        console.log("error");
        setLoading(false); // סיים את מצב הטעינה גם במקרה של שגיאה
      }
    };

    useEffect(() => {
      getAllEntries();
    }, [client]);

    return (
      <div id="layout" className="">
        <div className="content">
          <div>
            <div className="posts">
              <h1 className="content-subhead">מאמרים</h1>

              {/* בדיקה אם טעינה או לא באמצעות טרנרית */}
              {loading ? (
                <div className="loading-spinner">
                  <div className="spinner"></div>
                </div>
              ) : (
                blogPosts?.map((post) => (
                  <section className="post" key={post.sys.id}>
                    <header className="post-header">
                      <img
                        src={post.fields.blogImage.fields.file.url}
                        alt={post.fields.blogImage.fields.title}
                        width="578"
                        height="291"
                        loading="lazy"
                      />
                      <h2 className="post-title pt-3">{post.fields.blogTitle}</h2>
                      <p className="post-meta">
                        נכתב על ידי:{" "}
                        <a href="/" className="post-author">
                          {post.fields.blogAuthor} |{" "}
                        </a>
                        תאריך <span></span>
                        <small>{post.fields.createdDate}</small>
                      </p>
                    </header>

                    <div className="post-description">
                      <br></br>
                      <p>{post.fields.blogSummary}</p>
                      <Link
                        to={`/blogDetails/${post.fields.slug}`}
                        className="button button1"
                      >
                        קרא עוד
                      </Link>
                    </div>
                  </section>
                ))
              )}
            </div>

            <div className="blog-footer">
              <a href="/">חזרה</a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default BlogList;
